<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  // Your App component logic here
}
</script>

<style>
/* Your main styles here */
</style>
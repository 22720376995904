<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <section class="main-page subscription-page payment-page failure">
      <div class="container">
        <div class="header-logo"><img src="assets/images/aanantya-logo.png" alt=""></div>
        <div class="payment-status-text">
          <h4><i class="fa-solid fa-xmark"></i> Failure Payment</h4>
          <router-link to="/login">
            <button type="button">Back to login</button>
          </router-link>
        </div>
    </div>
    </section>
    </template>
    
    <script>
    
    export default {
      data() {
        return {
          cardElement: null,
        };
      },
      
      methods: {
       
      },
    };
    </script>
    
<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <section class="main-page choose-main">
        <div class="container">
            <div class="row about-us">
                <div class="col-md-8 col-sm-12 col-12">
                    <div class="about-us-inner">
                        <div class="about-scroller">
                            <div class="faq-section-inner">
                                <div class="accordion" id="accordionExample">

                                    <div class="accordion-item" v-for="(faq, index) in faqData" :key="index">
                                    <h2 class="accordion-header" :id="`heading${index}`">
                                        <button class="accordion-button" :class="{ 'collapsed': index !== 0 }" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${index}`" aria-expanded="true" :aria-controls="`collapse${index}`">
                                            {{ index + 1 }}. {{ faq.title }}
                                        </button>
                                    </h2>
                                    <div :id="`collapse${index}`" class="accordion-collapse collapse"  :class="{ 'show': index === 0 }" :aria-labelledby="`heading${index}`" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                        {{ faq.content }}
                                        </div>
                                    </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <beforeLoginChatbot></beforeLoginChatbot>
    </template>
    
    <script>

import { API } from "@/api/api";
import beforeLoginChatbot from '../views/beforeLoginChatbot.vue';
    
    export default {
        components: {
            // eslint-disable-next-line vue/no-unused-components
            beforeLoginChatbot
        },
      data() {
        return {
            // contentData: null,
            faqData: []
        };
      },

      created() {
    this.getContent();
  },
      
      methods: {
        getContent(){
            API.get(`faq/active?page=${1}&limit=${1000}`)
      .then(response => {
                this.faqData = response.data.data.rows;
                console.log(this.contentData, "After got contentData");
            }).catch(error => {
                  console.error("fail", error);
                  });
        }
      },
    };
    </script>
    
<!-- eslint-disable vue/multi-word-component-names -->
<template>
     <section class="main-page sign-in-page">
        <div class="container">
            <div class="header-logo"><img src="assets/images/aanantya-logo.png" alt=""></div>
            <div class="row sign-in-form">
                <div class="col-md-4 col-sm-12 col-12">
                    <form @submit.prevent="submitForm">
                         <div class="form-header">
                            <h3>Forgot Password</h3>
                            <p>Please enter your register email address</p>
                            <p class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</p>
                            <p class="successMsg" v-if="successMsg !== ''">{{ successMsg }}</p>
                         </div>
                        
                          <div class="form-devider field-type">
                            <label for="email" class="form-label">Email</label>
                            <input type="email" class="form-control" id="email" placeholder="email" v-model.trim="email" />
                            <span v-if="formSubmitted && !email">Email is required</span>
							<span v-else-if="formSubmitted && !validateEmail(email)">Please enter a valid email</span>
                          </div>
                          
                          <div class="form-devider">
                            <button type="submit" class="btn login-btn">Send</button>
                          </div>
                          
                          <div class="form-devider">
                            <router-link to="/login"><a href="javascript:void(0);">Back to Login</a></router-link>
                          </div>


                    </form>
                </div>
            </div>
        </div>
    </section>
 </template>
 
 <script>
 import { API } from "@/api/api";
 
 export default {
   // eslint-disable-next-line vue/multi-word-component-names
   name: "Forgot",
   data() {
     return {
       email: '',
       formSubmitted: false, // Track form submission status
       errorMsg:'',
       successMsg:''

     };
   },
   methods: {
       submitForm() {
     this.formSubmitted = true; // Set form submitted status
     if (this.validateForm()) {
       console.log('Form submitted:', this.email);
       const forgotData = {
         user_email: this.email
       }
        // Perform form submission (e.g., API call)
       API.post("auth/requestpasswordresetlink",  forgotData)
     .then(response => {
               console.log(response, "After Forgot mail send");
               this.successMsg = response.data.message;
               this.errorMsg = "";
              //  this.$router.push('/login'); // Navigates to the '/' (login) route
           }).catch(error => {
                 console.error("fail", error);
                 this.errorMsg = error.response.data.message;
                 this.successMsg = "";
                 });
     } else {
       console.log('Form has errors');
     }
   },
   validateEmail(email) {
     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
     return re.test(email);
   },
   validateForm() {
     return this.email &&
            this.validateEmail(this.email)
   },
   }
 };
 </script>
 
import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/Login.vue';
import Signup from '../views/Signup.vue';
import Forgot from '../views/Forgot.vue';
import Reset from '../views/Reset.vue';
import Subscription from '../views/Subscription.vue';
import Payment from '../views/Payment.vue';
import Dashboard from '../views/Dashboard.vue';
import Chatbot from '../views/Chatbot.vue';
import Success from '../views/Success.vue';
import Failure from '../views/Failure.vue';
import Terms from '../views/Termscondition.vue';
import About from '../views/Aboutus.vue';
import SuggestionInstruction from '../views/SuggestionInstruction.vue';
import Home from '../views/Home.vue';
import OurCommitment from '../views/OurCommitment.vue';
import ModernLinguistic from '../views/ModernLinguistic.vue';
import FAQ from '../views/FAQ.vue';
import ContactUs from '../views/ContactUs.vue';
import Blogs from '../views/Blogs.vue';
import BlogDetails from '../views/BlogDetails.vue';


const routes = [
    { 
      path: '/', 
      component: Home,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("userInfo")) === null ||JSON.parse(localStorage.getItem("userInfo")) === undefined ){
          next();
        }else{
          // console.log(JSON.parse(localStorage.getItem("userInfo")).isSubscribed, "MPPPPP")
          if(JSON.parse(localStorage.getItem("userInfo")).isSubscribed){
            window.location.href = '/dashboard';
          }else{
            next();
          }
        }  
      }
     },
     { 
      path: '/signup', 
      component: Signup,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("userInfo")) === null ||JSON.parse(localStorage.getItem("userInfo")) === undefined ){
          next();
        }else{
          // console.log(JSON.parse(localStorage.getItem("userInfo")).isSubscribed, "MPPPPP")
          if(JSON.parse(localStorage.getItem("userInfo")).isSubscribed){
            window.location.href = '/dashboard';
          }else{
            next();
          }
        }  
      }
     },
    { 
      path: '/login',
      component: Login,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("userInfo")) === null ||JSON.parse(localStorage.getItem("userInfo")) === undefined ){
          next();
        }else{
          // console.log(JSON.parse(localStorage.getItem("userInfo")).isSubscribed, "MPPPPP")
          if((!JSON.parse(localStorage.getItem("userInfo")).isSubscribed && 
          JSON.parse(localStorage.getItem("userInfo")).isTrialActivated) || 
          (JSON.parse(localStorage.getItem("userInfo")).isSubscribed && 
          !JSON.parse(localStorage.getItem("userInfo")).isTrialActivated)){
            window.location.href = '/dashboard';
          }else{
            next();
          }
        }  
      }
     },
    { path: '/forgot', component: Forgot },
    { path: '/reset', component: Reset },
    { path: '/terms-condition', component: Terms },
    { path: '/about-us', component: About },
    { path: '/our-commitment', component: OurCommitment },
    { path: '/modern-linguistic-techniques', component: ModernLinguistic },
    { path: '/faq', component: FAQ },
    { path: '/contact-us', component: ContactUs },
    { path: '/blogs', component: Blogs },
    { path: '/blog/:blogUniqueId',  component: BlogDetails },
    { 
      path: '/subscription',
      component: Subscription,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("userInfo")) === null ||JSON.parse(localStorage.getItem("userInfo")) === undefined){
          window.location.href = '/login';
        } else {
          if((JSON.parse(localStorage.getItem("userInfo")).isSubscribed &&
          !JSON.parse(localStorage.getItem("userInfo")).isTrialActivated) || 
          (!JSON.parse(localStorage.getItem("userInfo")).isSubscribed &&
          JSON.parse(localStorage.getItem("userInfo")).isTrialActivated)){
            window.location.href = '/dashboard';
          }else{
            next();
          }
        }
      }
     },
    { path: '/payment', component: Payment },
    { 
      path: '/success',
      component: Success, 
      beforeEnter: (to, from, next) => {
          if(JSON.parse(localStorage.getItem("userInfo")) === null ||JSON.parse(localStorage.getItem("userInfo")) === undefined){
            window.location.href = '/login';
          } else {
            next();
          }
        }
    },
    { 
      path: '/failure',
      component: Failure, 
      beforeEnter: (to, from, next) => {
          if(JSON.parse(localStorage.getItem("userInfo")) === null ||JSON.parse(localStorage.getItem("userInfo")) === undefined){
            window.location.href = '/login';
          } else {
            next();
          }
        }
    },
    { 
      path: '/dashboard',
      component: Dashboard, 
      beforeEnter: (to, from, next) => {
          if(JSON.parse(localStorage.getItem("userInfo")) === null 
          || JSON.parse(localStorage.getItem("userInfo")) === undefined
          || (JSON.parse(localStorage.getItem("userInfo")).isSubscribed === false &&
          JSON.parse(localStorage.getItem("userInfo")).isTrialActivated === false )){
            window.location.href = '/login';
          } else {
            next();
          }
        }
    },
    {
      path: '/suggestions-instructions',
      component: SuggestionInstruction, 
      beforeEnter: (to, from, next) => {
          if(JSON.parse(localStorage.getItem("userInfo")) === null 
          || JSON.parse(localStorage.getItem("userInfo")) === undefined
          || (JSON.parse(localStorage.getItem("userInfo")).isSubscribed === false &&
          JSON.parse(localStorage.getItem("userInfo")).isTrialActivated === false )){
            window.location.href = '/login';
          } else {
            next();
          }
        }
    },
    // { 
    //   path: '/dashboard/:uuid',
    //   name: 'Dashboard', 
    //   component: Dashboard,
    //   beforeEnter: (to, from, next) => {
    //     if(JSON.parse(localStorage.getItem("userInfo")) === null ||JSON.parse(localStorage.getItem("userInfo")) === undefined){
    //       window.location.href = '/login';
    //     } else {
    //       next();
    //     }
    //   }
    //  },
    { 
      path: '/chatbot',
      component: Chatbot,
      beforeEnter: (to, from, next) => {
        if(JSON.parse(localStorage.getItem("userInfo")) === null ||JSON.parse(localStorage.getItem("userInfo")) === undefined){
          window.location.href = '/login';
        } else {
          next();
        }
      }
     }
  // Other routes...
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;


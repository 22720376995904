<!-- eslint-disable vue/valid-template-root -->
// eslint-disable-next-line vue/valid-template-root
<template>

</template>
    
    <script>
//    import { API } from "@/api/api";
    
    export default {
      data() {
        return {
        };
      },
      methods: {
        loadVoiceFlowChat() {
        const script = document.createElement('script');
        const firstScript = document.getElementsByTagName('script')[0];

        script.onload = () => {
            window.voiceflow.chat.load({
            verify: { projectID: '65785387a484f9a585032e17' },
            url: 'https://general-runtime.voiceflow.com',
            versionID: 'production'
            });
        };

        script.src = 'https://cdn.voiceflow.com/widget/bundle.mjs';
        script.type = 'text/javascript';
        firstScript.parentNode.insertBefore(script, firstScript);
        }
     },
     mounted() {
        this.loadVoiceFlowChat();
     }
    };
    </script>
    
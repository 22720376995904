<!-- eslint-disable vue/multi-word-component-names -->
<template>
     <section class="main-page dashboard-main">
        <div class="dashboard-inner">
            <div class="dashboard-iner-lft">
                <div class="dashboard-hder-logo">
                    <img src="assets/images/white-logo.png" alt=""/>
                </div>
                <div class="dashboard-lft-nwchat">
                    <a href="#"><img src="assets/images/messages.png" alt=""/> New Chat</a>
                </div>
                <div class="chart-history">
                    <ul class="list-inline">
                        <li><a href="#"><img src="assets/images/learn.png" alt="">I want to learn Sanskrit</a></li>
                        <li><a href="#"><img src="assets/images/bow.png" alt="">Who killed Abhimanyu</a></li>
                        <li><a href="#"><img src="assets/images/quill-pens.png" alt="">Who Wrote Mahabharata</a></li>
                        <li><a href="#"><img src="assets/images/quill-pen.png" alt="">How to write in Sanskrit</a></li>
                        <li><a href="#"><img src="assets/images/sanskrit.png" alt="">How to converse in Sankrit</a></li>
                        <li><a href="#"><img src="assets/images/book1.png" alt="">Best ways to learn quickly</a></li>
                    </ul>
                </div>
                
                <div class="acount-setting">
                    <div class="acount-details dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <span><img src="assets/images/profile.png" alt=""></span>
                        <p>Savannah Nguyen</p>
                        <span><img src="assets/images/acount-bar.png" alt=""></span>
                    </div>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#"><i class="fa-regular fa-file-excel"></i> Cancel Subscription </a></li>
                        <li><a class="dropdown-item" href="#"><i class="fa-solid fa-right-from-bracket"></i> Log out</a></li>
                    </ul>
                </div>
            </div>
            <div class="dashboard-iner-rgt chatgpt-main">
                <div class="chartbot-header">
                    <h4>who killed Abhimanyu?</h4>
                </div>
                <div class="chartbot-answer-section">
                    <p>संस्कृतम् जगतः एकतमा अतिप्राचीना समृद्धा शास्त्रीया च भाषासु वर्तते । संस्कृतं भारतस्य जगत: वा भाषासु एकतमा प्राचीनतमा । यो न हृष्यति न द्वेष्टि न शोचति न काङ्‍क्षति। शुभाशुभपरित्यागी भक्तिमान्यः स मे प्रियः॥</p>
                    <p>कर्मेन्द्रियाणि संयम्य य आस्ते मनसा स्मरन् । इन्द्रियार्थान्विमूढात्मा मिथ्याचारः स उच्यते ॥</p>
                    <p>न हि कश्चित्क्षणमपि जातु तिष्ठत्यकर्मकृत् । कार्यते ह्यवशः कर्म सर्वः प्रकृतिजैर्गुणैः॥</p>
                    <p>यो मामजमनादिं च वेत्ति लोकमहेश्वरम् । असम्मूढः स मर्त्येषु सर्वपापैः प्रमुच्यते ॥</p>
                    <p>कर्मण्येवाधिकारस्ते मा फलेषु कदाचन।</p>
                    <p>मा कर्मफलहेतुर्भूर्मा ते सङ्गोऽस्त्वकर्मणि</p>
                    <p>हतो वा प्राप्यसि स्वर्गम्, जित्वा वा भोक्ष्यसे महिम्।
                        तस्मात् उत्तिष्ठ कौन्तेय युद्धाय कृतनिश्चय:॥</p>
                    <p>ध्यायतो विषयान्पुंसः सङ्गस्तेषूपजायते। </p>
                    <p>सङ्गात्संजायते कामः कामात्क्रोधोऽभिजायते॥</p>
                    
                    












                </div>
                <div class="question-type">
                    <input type="text" value="" placeholder="Type...">
                    <button type="submit" class="btn">Send Now</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// import { API } from "@/api/api";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Chatbot",
  data() {
    return {
    };
  },
  methods: {
  
  }
};
</script>

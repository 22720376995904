<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <!-- Top Header start here -->
    <header class="top-bar">
        <div class="custom-container">
            <nav class="navbar navbar-expand-lg">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#"><img src="assets/images/landing-page-logo.png" alt=""></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <router-link to="/" class="nav-link active" aria-current="page">Home</router-link>
                            </li>
                        </ul>
                        <div class="d-flex login-btn-headr">
                            <router-link to="/login" class="btn global-btn">Login</router-link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </header>
    <!-- Top Header end here -->
    <!-- Banner section start -->
    <section class="banner-section">
        <div class="custom-container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="banner-left-cntn">
                        <span v-html="landingtData.firstSection.textContent"></span>
                        <!-- <router-link to="/signup" class="global-btn">Join Now</router-link> -->
                    </div>
                    <div class="aanantya-video">
                        <span v-html="landingtData.firstSection.videoSource"></span>
                        <div class="video_caption">
                            <span class="text-center d-block" v-html="landingtData.firstSection.videoTextContent">
                            </span>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div class="banner-right-thumb">
                        <img :src="landingtData.firstSection.imageSource" alt="">
                    </div>
                </div> -->
            </div>
        </div>
    </section>
    <!-- Banner section end -->
    <!-- aanantya video section start-->
    <!-- <section class="aanantya-video">
        <div class="container">
            <div class="global-heading">
                <h2 v-html="landingtData.secondSection.title"></h2>
            </div>
            <span v-html="landingtData.firstSection.videoSource"></span>
            <div class="video_caption">
                <span class="text-center d-block">{{ landingtData.firstSection.videoTextContent }} 
                </span>
            </div>
        </div>
    </section> -->
    <!-- aanantya video section end -->
    <!-- learning tamil start -->
    <section class="learning-tamil bg_anant cstm_padding">
        <div class="custom-container">
            <!-- <div class="global-heading">
                <h2>{{ landingtData.thirdSection.title }}</h2>
                <p>{{ landingtData.thirdSection.subTitle }}</p>
            </div> -->
            <div class="learning-tamil-items">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="row justify-content-center">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                                v-for="(grid, index) in landingtData.firstSection.gridArray" :key="index">
                                <div class="learning-tamil-item">
                                    <span v-html="grid"></span>
                                    <!-- <h4>Cultural Immersion and Heritage Connecion:</h4>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda deleniti, eligendi dolores architecto nesciunt harum odio laudantium officiis in voluptates dicta doloremque! Quidem ad ipsa quod assumenda corporis qui vel!</p>
                            <a href="#">Read More <i class="fa-solid fa-angles-right"></i></a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="stay-in-touch">
                    <div class="global-heading newsletter_heading">
                        <h2>{{ landingtData.firstSection.emailTextContent }}</h2>
                    </div>
                    <div class="newsletter_block">
                        <form action="">
                            <div class="form_group">
                                <input type="email" name="" id="" placeholder="Email">
                                <input type="submit" value="Email">
                            </div>
                        </form>
                        <span>or</span>
                        <router-link to="/signup" class="global-btn">Join Now</router-link>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-12 text-center join-now-btn">
                        <router-link to="/signup" class="global-btn">Join Now</router-link>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
    <!-- learning tamil end -->
    <!-- every aspiration tamil start -->
    <section class="aspiration-tamil">
        <div class="custom-container">
            <div class="global-heading">
                <h2>{{ landingtData.secondSection.title }}</h2>
                <p>{{ landingtData.secondSection.subTitle }}</p>
            </div>
            <div class="aspiration-inner-items">
                <div class="row justify-content-center">

                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12"
                        v-for="(grid, index) in landingtData.secondSection.gridArray" :key="index">
                        <div class="aspiration-item">
                            <!-- <h3>{{ grid.title }}</h3> -->
                            <div class="aspirant-thumbnail">
                                <img :src="grid.imageSource" alt="">
                            </div>
                            <div class="aspiration-item-cntn" v-html="grid.textContent"></div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12 text-center join-now-btn">
                        <router-link to="/signup" class="global-btn">Join Now</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- every aspiration tamil end -->
    <!-- Faq section start -->
    <section class="faq-section">
        <div class="custom-container">
            <div class="global-heading">
                <h2>Frequently Asked Questions (more questions and answers follow in footer)</h2>
            </div>
            <div class="faq-section-inner">
                <div class="accordion" id="accordionExample">

                    <div class="accordion-item" v-for="(faq, index) in faqData" :key="index">
                        <h2 class="accordion-header" :id="`heading${index}`">
                            <button class="accordion-button" :class="{ 'collapsed': index !== 0 }" type="button"
                                data-bs-toggle="collapse" :data-bs-target="`#collapse${index}`" aria-expanded="true"
                                :aria-controls="`collapse${index}`">
                                {{ index + 1 }}. {{ faq.title }}
                            </button>
                        </h2>
                        <div :id="`collapse${index}`" class="accordion-collapse collapse" :class="{ 'show': index === 0 }"
                            :aria-labelledby="`heading${index}`" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                {{ faq.content }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <!-- Faq section end -->
       <!-- most trending blog start -->
       <section class="most-trending-blog">
        <div class="custom-container">
            <div class="global-heading">
                <h2>{{ landingtData.fifthSection.blogsHeading }}</h2>
                <p>{{ landingtData.fifthSection.blogsSubHeading }}</p>
            </div>
            <div class="blog-items-list">
                <div class="row">

                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12" v-for="(blog, index) in blogData" :key="index">
                        <div class="blog-inner-item">
                            <div class="blog-item-thumb">
                                <img :src="blog.coverImageUrl" alt="">
                            </div>
                            <h3>{{ blog.title.substring(0, 48) }}</h3>
                            <router-link :to="`/blog/${blog.blogUniqueId}`" class="read-more-btn">Read More <i
                                    class="fa-solid fa-angles-right"></i></router-link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <!-- most trending blog end -->
    <!-- Benefit seekers section start -->
    <!-- <section class="beefir-seekers">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                    <div class="banner-left-cntn" v-html="landingtData.fifthSection.textContent"></div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div class="banner-right-thumb">
                        <img :src="landingtData.fifthSection.imageSource" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- Benefit seekers section end -->
    <!-- learning tamil start -->
    <section class="learning-tamil richness-tamil-literature">
        <div class="custom-container">
            <div class="global-heading">
                <h2>{{ landingtData.fourthSection.title }}</h2>
                <p>{{ landingtData.fourthSection.subTitle }}</p>
            </div>
            <div class="learning-tamil-items">
                <!-- <div class="row justify-content-center">

                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                    v-for="(grid, index) in landingtData.sixthSection.gridArray" :key="index">
                        <div class="learning-tamil-item" v-html="grid"></div>
                    </div>
                    
                </div> -->
                <div class="row justify-content-center">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-2" v-for="(gridData, index) in landingtData.fourthSection.gridArray" :key="index">
                        <!-- Even Section -->
                        <div class="learning-tamil-item" v-if="index % 2 === 0">
                            <span v-html="gridData.textContentOrImage"></span>
                        </div>

                        <!-- Odd Section -->

                        <div class="learning-tamil-item anant_img_box" v-else>
                            <img alt="" :src="gridData.textContentOrImage">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-center">
                        <h6>{{ landingtData.fourthSection.bottomContent }}</h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center join-now-btn">
                        <router-link to="/signup" class="global-btn">Join Now</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- learning tamil end -->
 
    <!-- learning tamil start -->
    <section class="learning-tamil learning-module">
        <div class="custom-container">
            <div class="global-heading">
                <h2>{{ landingtData.sixthSection.title }}</h2>
                <p>{{ landingtData.sixthSection.subTitle }}</p>
            </div>
            <div class="learning-tamil-items">
                <div class="row justify-content-center">

                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12"
                        v-for="(grid, index) in landingtData.sixthSection.gridArray" :key="index">
                        <div class="learning-tamil-item">
                            <img :src="grid.imageSource" alt="">
                            <span v-html="grid.textContent"></span>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12 text-center join-now-btn">
                        <router-link to="/signup" class="global-btn">Join Now</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- learning tamil end -->
    <!-- footer section start -->
    <footer class="footer">
        <div class="custom-container">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div class="footer-inner">
                        <h4>Services</h4>
                        <ul class="list-inline">
                            <!-- <li><a href="#">Pricing</a></li> -->
                            <li><router-link to="/signup">Registration</router-link></li>
                            <li><router-link to="/our-commitment">Our Commitment</router-link></li>
                            <li><router-link to="/modern-linguistic-techniques">Modern Linguistic Techniques</router-link>
                            </li>
                            <li><router-link to="/faq">FAQ</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div class="footer-inner">
                        <h4>Quick Links</h4>
                        <ul class="list-inline">
                            <li><router-link to="/blogs">Blog</router-link></li>
                            <li><router-link to="/terms-condition">Terms & Conditions</router-link></li>
                            <li><router-link to="/terms-condition">Privacy Policy</router-link></li>
                            <li><router-link to="/about-us">About Us</router-link></li>
                            <li><router-link to="/contact-us">Contact Us</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div class="footer-inner">
                        <h4>Contact Us</h4>
                        <ul class="list-inline">
                            <li><a href="javascript:void(0);">+832 439 6212</a></li>
                            <li><a href="javascript:void(0);">care@aanantya.co</a></li>
                        </ul>
                    </div>
                    <div class="footer-inner">
                        <h4>Locations</h4>
                        <ul class="list-inline">
                            <li>Kolkata, India</li>
                            <li>Delhi, India</li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div class="footer-inner">
                        <h4>Subscribe to our Newsletter</h4>
                        <div class="footer-subscribe-field">
                            <input type="text" placeholder="Email Address">
                            <button type="button">Subscribe</button>
                        </div>
                    </div>
                    <div class="footer-inner social-links">
                        <h4>Get in Touch</h4>
                        <ul class="list-inline">
                            <li><a href="javascript:void(0);"><i class="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="javascript:void(0);"><i class="fa-brands fa-instagram"></i></a></li>
                            <li><a href="javascript:void(0);"><i class="fa-brands fa-twitter"></i></a></li>
                            <li><a href="javascript:void(0);"><i class="fa-brands fa-pinterest"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <section class="copuright-section">
        <div class="container">
            <p>Copyright 2024 All Right Reserved <a href="https://hindi.aanantya.co/">hindi.aanantya.co</a></p>
        </div>
    </section>
    <!-- footer section end -->

    <beforeLoginChatbot></beforeLoginChatbot>
    <!-- Landing Page body end -->
</template>
    
<script>

import { API } from "@/api/api";
import beforeLoginChatbot from './beforeLoginChatbot.vue';

export default {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        beforeLoginChatbot
    },
    data() {
        return {
            landingtData: {
                firstSection: {
                    textContent: '',
                    videoSource: null,
                    videoTextContent: '',
                    gridArray: [],
                    emailTextContent: ''
                },
                secondSection: {
                    title: '',
                    subTitle: '',
                    gridArray: []
                },

                fourthSection: {
                    title: '',
                    subTitle: '',
                    gridArray: [],
                    bottomContent: ''
                },
                fifthSection: {
                    blogsHeading: '',
                    blogsSubHeading: ''
                },
                sixthSection: {
                    title: '',
                    subTitle: '',
                    gridArray: []
                }
            },
            faqData: [],
            blogData: []
        };
    },

    created() {
        this.getContent();
        this.getFaqDetails();
        this.getBlogDetails();
    },

    methods: {
        getContent() {
            API.get("generic/landingpage")
                .then(response => {
                    this.landingtData = response.data.data;
                    console.log(this.landingtData, "After got contentData", response);
                }).catch(error => {
                    console.error("fail", error);
                });
        },
        getFaqDetails() {
            API.get(`faq/active?page=${1}&limit=${4}`)
                .then(response => {
                    this.faqData = response.data.data.rows;
                    console.log(this.faqData, "After got Faq Data");
                }).catch(error => {
                    console.error("fail", error);
                });

        },
        getBlogDetails() {
            API.get(`blog/active?page=${1}&limit=${4}`)
                .then(response => {
                    this.blogData = response.data.data.rows;
                    console.log(this.blogData, "After got Blog Data");
                }).catch(error => {
                    console.error("fail", error);
                });
        }
    },
};
</script>
    
<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <section class="main-page choose-main">
        <div class="container">
            <div class="row about-us">
                <div class="col-md-8 col-sm-12 col-12">
                    <div class="about-us-inner">
                        <div class="about-scroller">
                            <span v-html="contentData"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <beforeLoginChatbot></beforeLoginChatbot>
    </template>
    
    <script>

import { API } from "@/api/api";
import beforeLoginChatbot from '../views/beforeLoginChatbot.vue';
    
    export default {
        components: {
            // eslint-disable-next-line vue/no-unused-components
            beforeLoginChatbot
        },
      data() {
        return {
            contentData: null
        };
      },

      created() {
    this.getContent();
  },
      
      methods: {
        getContent(){
            API.get("generic/modernlinguistictechnique")
      .then(response => {
                this.contentData = response.data.data;
                console.log(this.contentData, "After got contentData");
            }).catch(error => {
                  console.error("fail", error);
                  });
        }
      },
    };
    </script>
    
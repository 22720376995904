import { loadStripe } from '@stripe/stripe-js';

let stripePromise;

export const getStripe = async () => {
    if (!stripePromise) {
      stripePromise = loadStripe('pk_test_51O7kDmSBY8ouOqBQutLgrX9PMhnIAICi5UL90ZUkyPpY8Pnm9RyqA6pd1foHlRDvuhfjTw2ko8PwOBAB8gRb3WUe00T95mO79S');
    }
    return stripePromise;
  };

export const createToken = async (cardElement) => {
  const stripe = await getStripe();
  const elements = stripe.elements();
  console.log(elements);
  const { error, token } = await stripe.createToken(cardElement);
  if (error) {
    console.error(error);
    return { error: error.message };
  } else {
    return { token };
  }
};

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueStripeElementsPlus from 'vue-stripe-elements-plus';

const app = createApp(App);
app.use(router);
app.use(VueStripeElementsPlus, {
    apiKey: 'pk_test_51O7kDmSBY8ouOqBQutLgrX9PMhnIAICi5UL90ZUkyPpY8Pnm9RyqA6pd1foHlRDvuhfjTw2ko8PwOBAB8gRb3WUe00T95mO79S',
  });
app.mount('#app');

<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="lds-ripple-content" v-if="isLoading"><div class="lds-ripple"><div></div><div></div></div></div>
    <section class="main-page subscription-page payment-page">
      <div class="container">
        <div class="header-logo"><img src="assets/images/aanantya-logo.png" alt=""></div>
        <div class="payment-status-text">
          <h4><i class="fa-solid fa-check"></i> Payment done successfully</h4>
          <button type="button" @click="verifySubscription">Ok</button>
        </div>
    
    </div>
    </section>
    </template>
    
    <script>
   import { API } from "@/api/api";
    
    export default {
      data() {
        return {
          isLoading: false
        };
      },
      methods: {
        verifySubscription(){
          this.isLoading = true;
            const obj = {
                session_id: this.$route.query.session_id
        };
            var head = {
            headers: {
                "x-access-token": JSON.parse(localStorage.getItem("Token"))
            }
          };
        API.post("subscription/verifysubscription", obj, head)
      .then(response => {
                console.log(response, "After call verifySubscription");
                localStorage.setItem("userInfo", JSON.stringify(response.data.data));
                this.isLoading = false;
                this.$router.push('/dashboard');
            }).catch(error => {
                  console.error("fail", error);
                  this.isLoading = false;
                  });
        }
      },
    };
    </script>
    
<template>
  <div class="lds-ripple-content" v-if="isLoading"><div class="lds-ripple"><div></div><div></div></div></div>
     <section class="main-page sign-in-page">
        <div class="container">
            <div class="header-logo"><img src="assets/images/aanantya-logo.png" alt=""></div>
            <div class="row sign-in-form">
                <div class="col-md-4 col-sm-12 col-12">
                    <form @submit.prevent="submitForm">
                         <div class="form-header">
                            <h3>Choose Password</h3>
                            <p>Please enter a new password</p>
                            <p class="errmsg" v-if="errorMsg !== ''">{{ errorMsg }}</p>
                         </div>
                         <div class="form-devider field-type">
                            <label for="password" class="form-label">Password</label>
                            <input type="password" class="form-control" id="password" placeholder="password" v-model.trim="formData.password" />
                            <span v-if="formSubmitted && !formData.password">Password is required</span>
                          </div>
                          <div class="form-devider field-type">
                            <label for="password" class="form-label">Confirm New Password</label>
                            <input type="password" class="form-control" id="cpassword" placeholder="confirm password" v-model.trim="formData.confirmPassword" />
                            <!-- <span v-if="formSubmitted && !formData.confirmPassword">Confirm Password is required</span> -->
                            <span v-if="formSubmitted && !passwordsMatch">Passwords do not match</span>
                          </div>
                          
                          
                          <div class="form-devider">
                            <button type="submit" class="btn login-btn">Update</button>
                          </div>
                          
                    </form>
                </div>
            </div>
        </div>
    </section>
 </template>
 
 <script>
 import { API } from "@/api/api";
 
 export default {
   // eslint-disable-next-line vue/multi-word-component-names
   name: "Reset",
   data() {
     return {
        formData: {
            password: '',
            confirmPassword:''
      },
       resetToken: null,
       email: null,
       formSubmitted: false, // Track form submission status
       errorMsg:'',
       isLoading: false
     };
   },
   computed: {
    passwordsMatch() {
      return this.formData.password === this.formData.confirmPassword;
    }
  },
   mounted() {
    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    // console.log('urlParams', urlParams);
    
    // Extract the values
    const resetToken = urlParams.get('reset_password_token');
    const email = urlParams.get('email');

    // Use the retrieved token and email as needed (for example, log to the console)
    // console.log('Reset Token:', resetToken);
    // console.log('Email:', email);

    // You can set these values to data properties if you want to use them in your component
    this.resetToken = resetToken;
    this.email = email;
  },
   methods: {
    submitForm() {
      this.isLoading = true;
      this.formSubmitted = true; // Set form submitted status
      if (this.validateForm()) {
        
        const resetData = {
          user_email: this.email,
          token: this.resetToken,
          user_password: this.formData.password,
        }
         // Perform form submission (e.g., API call)
        API.post("auth/resetpassword",  resetData)
      .then(response => {
                console.log(response, "After password match");
                this.isLoading = false;
                this.$router.push('/login');
            }).catch(error => {
                  console.error("fail", error);
                  this.errorMsg = error.response.data.message;
                  this.isLoading = false;
                  });
      } else {
        console.log('Form has errors');
        this.isLoading = false;
      }
    },
    validateForm() {
      return this.formData.password &&
             this.formData.confirmPassword &&
             this.passwordsMatch // Check passwords match;
    },
   }
 };
 </script>
 
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section class="main-page subscription-page payment-page">
    <div class="container">
      <div class="header-logo"><img src="assets/images/aanantya-logo.png" alt=""></div>
    <div class="row sign-in-form">
      <div class="col-xl-5 col-md-7 col-sm-12 col-12">
        <div class="subscription-main">
          <h4>Checkout</h4>
          <div class="subscrip-iner">
      <form @submit.prevent="processPayment">
        <div>
          <label for="card-element">
            Credit or debit card
          </label>
          <div id="card-element"></div>
          <div id="card-errors" role="alert">
            <!-- This is where the card error messages will be displayed -->
          </div>
        </div>
  
        <button type="submit" data-bs-toggle="modal" data-bs-target="#paymentModal">Pay</button>
      </form>
    </div>
    </div>
    </div>
    </div>
  </div>
  <div class="modal fade" id="paymentModal" tabindex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="paymentModalLabel">Payment Status</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h6>Payment Success</h6>
      </div>
   
    </div>
  </div>
</div>
  </section>
  </template>
  
  <script>
  import { createToken, getStripe } from '../services/stripeService';
  
  export default {
    data() {
      return {
        cardElement: null,
      };
    },
    async mounted() {
      const stripe = await getStripe();
      const elements = stripe.elements();
      const style = {
        base: {
          fontSize: '16px',
          color: '#32325d',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#dc3545',
        },
  };
          this.cardElement = elements.create('card', {
          style,
          hidePostalCode: true // This option hides the postal code field
        });
      this.cardElement.mount('#card-element');

      // Handle card validation and display error messages
        this.cardElement.addEventListener('change', (event) => {
          const displayError = document.getElementById('card-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
          }
        });
    },
    methods: {
      async processPayment() {
        const { token, error } = await createToken(this.cardElement);
        if (error) {
          console.error(error);
          console.log(error.message);
        } else {
          console.log(token);
          // Handle the token (e.g., send it to your server for payment processing)
        }
      },
    },
  };
  </script>
  
<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <section class="main-page choose-main">
        <div class="container">
            <div class="row about-us">
                <div class="col-md-8 col-sm-12 col-12">
                    <div class="about-us-inner">
                        <div class="about-scroller">
                            <div class="blog-items-list blog_details">
                                <div class="row">

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div class="blog-inner-item">
                                            <div class="blog-item-thumb">
                                                <img :src="blogDataById.coverImageUrl" alt="">
                                            </div>
                                            <h3>{{ blogDataById.title }}</h3>
                                            <p v-html="blogDataById.content"></p>
                                        </div>
                                    </div>

                                    <router-link to="/blogs">Back To All Blogs</router-link>

                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <beforeLoginChatbot></beforeLoginChatbot>
    </template>
    
    <script>

import { API } from "@/api/api";
import beforeLoginChatbot from './beforeLoginChatbot.vue';
    
    export default {
        components: {
            // eslint-disable-next-line vue/no-unused-components
            beforeLoginChatbot
        },
      data() {
        return {
            blogDataById: Object
        };
      },

      created() {
        this.getBlogDetailsById();
  },
      
      methods: {
        getBlogDetailsById(){
            API.get(`blog/details/?blogId=${this.$route.params.blogUniqueId}`)
      .then(response => {
                this.blogDataById = response.data.data;
                console.log(this.blogDataById, "After got Blog Data by id");
            }).catch(error => {
                  console.error("fail", error);
                  });
        }
      },
    };
    </script>
    
<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <section class="main-page choose-main">
        <div class="container">
            <div class="row about-us">
                <div class="col-md-8 col-sm-12 col-12">
                    <div class="about-us-inner">
                        <div class="about-scroller">
                            <div class="blog-items-list">
                                <div class="row">

                                    <div class="col-sm-12"
                                    v-for="(blog, index) in blogData" :key="index">
                                        <div class="blog-inner-item">
                                            <!-- <div class="blog-item-thumb">
                                                <img :src="blog.coverImageUrl" alt="">
                                            </div> -->
                                            <router-link :to="`/blog/${blog.blogUniqueId}`" class="blog_list_link"><h3>{{ blog.title.substring(0, 48) }}</h3></router-link>
                                            <!-- <router-link :to="`/blog/${blog.blogUniqueId}`" class="read-more-btn">Read More <i class="fa-solid fa-angles-right"></i></router-link> -->
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <beforeLoginChatbot></beforeLoginChatbot>
    </template>
    
    <script>

import { API } from "@/api/api";
import beforeLoginChatbot from '../views/beforeLoginChatbot.vue';
    
    export default {
        components: {
            // eslint-disable-next-line vue/no-unused-components
            beforeLoginChatbot
        },
      data() {
        return {
            blogData: []
        };
      },

      created() {
        this.getBlogDetails();
  },
      
      methods: {
        getBlogDetails(){
            API.get(`blog/active?page=${1}&limit=${100}`)
      .then(response => {
                this.blogData = response.data.data.rows;
                console.log(this.blogData, "After got Blog Data");
            }).catch(error => {
                  console.error("fail", error);
                  });
        }
      },
    };
    </script>
    
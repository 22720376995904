<!-- eslint-disable vue/multi-word-component-names -->
<template>
   <section class="main-page term-condition-baner">
        <div class="container">
            <div class="row about-us">
                <div class="col-md-8 col-sm-12 col-12">
                    <div class="about-us-inner">
                        <div class="about-scroller">
                            <span v-html="contentData"></span>
                            <!-- <div class="about-us-cntn">
                                <h3>Introduction and Acceptance of Terms</h3>
                                <h5>1.1 Welcome to Aanantya.co</h5>
                                <p>Aanantya.co is a dynamic online educational platform dedicated to the promotion and learning of the Samskrit language. Our services include, but are not limited to, language tutoring, access to educational resources, and interactive community engagement, specifically designed to cater to a wide range of learners from beginners to advanced students. This platform is operated by Stunning Vistas Inc., a non-profit organization committed to the revitalization and dissemination of Samskrit language and culture.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>1.2 Agreement to Terms</h5>
                                <p>By accessing, browsing, or otherwise using the aanantya.co website and its associated services and features, you, as the user, agree to be bound by these Terms and Conditions (the "Terms"). These Terms constitute a legally binding agreement between you and aanantya.co, and by using the platform, you represent that you have the full legal capacity to enter into this agreement. If you are using the platform on behalf of an organization or entity, you represent that you have the authority to bind such organization or entity to these Terms.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>1.3 Amendments and Revisions</h5>
                                <p>Aanantya.co reserves the right to modify or revise these Terms at any time without prior notice. Your continued use of the platform after any such changes constitutes your acceptance of the new Terms. We encourage users to periodically review this page for the latest information on our Terms.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>1.4 Scope of Service</h5>
                                <p>The services provided by aanantya.co include, but are not limited to, Samskrit language tutorials, educational resources, conversational practice with AI-driven chatbots, community interaction, and access to a wealth of Samskrit literature and knowledge. These services are designed to be accessible to a diverse user base, including individual learners, academic institutions, and professionals.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>1.5 Eligibility and Registration</h5>
                                <p>To access certain features of the platform, you may be required to register and create an account. By registering, you agree to provide accurate, current, and complete information and to update this information to maintain its accuracy. Aanantya.co reserves the right to suspend or terminate accounts that are found to contain inaccurate or incomplete information.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>1.6 User Conduct</h5>
                                <p>As a user of aanantya.co, you agree to adhere to all applicable laws and regulations and to respect the intellectual property rights of the content available on the platform. You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>1.7 Contact and Communication</h5>
                                <p>By using aanantya.co, you agree to receive communications from us, including but not limited to, emails, newsletters, and promotional offers. You may opt out of receiving certain communications as provided in our Privacy Policy.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h3>Scope of Services</h3>
                                <h5>2.1 Language Education and Resources</h5>
                                <p>Aanantya.co is devoted to providing comprehensive Samskrit language education. Our services encompass a range of learning materials and methodologies, catering to diverse learning needs. This includes but is not limited to lessons for beginners, intermediate learners, and advanced students. Our platform offers a unique blend of traditional and innovative teaching methods, integrating AI-driven tools to enhance the learning experience.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>2.2 Samskrit Commons Community</h5>
                                <p>We believe in the power of community learning and exchange. To this end, we have established the Samskrit Commons, a vibrant online community where learners and educators can interact, share knowledge, and engage in Samskrit language activities throughout the year. The Commons serves as a hub for cultural exchange and language practice, facilitating peer-to-peer learning and expert guidance.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>2.3 Educational Tools and Interactive Features</h5>
                                <p>Our platform leverages advanced technology to deliver a rich educational experience. This includes interactive features like AI-driven chatbots for conversational practice, forums for discussion, live sessions for real-time learning, and a variety of multimedia resources. These tools are designed to provide a comprehensive and immersive learning environment.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>2.4 Access to Knowledge and Literature</h5>
                                <p>Aanantya.co provides access to a wide array of Samskrit literature and knowledge systems. This includes ancient texts, modern interpretations, and a vast repository of Samskrit resources, both in original Samskrit and translated forms. Our platform serves as a bridge connecting learners to the rich heritage of Samskrit literature and philosophy.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>2.5 Tailored Learning Paths</h5>
                                <p>Recognizing the diversity in learning goals and levels, aanantya.co offers tailored learning paths. Whether you are a beginner looking to understand basic concepts or an advanced learner seeking in-depth study, our platform provides structured courses and resources to meet your individual needs.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>2.6 Continuous Content Updates and Expansion</h5>
                                <p>We are committed to continually updating and expanding our content and resources. This includes adding new lessons, enhancing interactive features, and incorporating user feedback to improve the learning experience. Our goal is to ensure that aanantya.co remains a dynamic and evolving platform that meets the changing needs of our learners.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>2.7 Special Programs for Educational Institutions</h5>
                                <p>Aanantya.co offers specialized programs and resources for educational institutions. This includes providing free access to our platform for academic institutions and professionals engaged in Samskrit education. We aim to support the academic community by offering high-quality resources and tools to aid in teaching and learning.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h3>User Base</h3>
                                <h5>3.1 Target Audience</h5>
                                <p>Aanantya.co is designed to cater to a wide array of users with varying interests and proficiency levels in Samskrit language. Our primary users include individual learners, educational institutions, Samskrit language professionals, and enthusiasts. The platform is structured to meet the diverse needs of these different user groups, providing tailored experiences for each.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>3.2 Individual Learners</h5>
                                <p>Individual users, ranging from beginners to advanced learners, can access a variety of resources and tools tailored to their specific learning stage and goals. The platform offers a supportive and engaging environment for individual study, providing tools for self-paced learning and opportunities for interactive practice.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>3.3 Educational Institutions and Professionals</h5>
                                <p>Educational institutions and Samskrit language professionals are given complimentary access to aanantya.co as a teaching aid. The platform serves as an invaluable resource for these institutions, offering materials that can complement traditional teaching methods and provide additional support to students and educators alike.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>3.4 Access for Academic and Professional Purposes</h5>
                                <p>Aanantya.co encourages the use of its resources for academic and professional development. We provide specialized content and tools that can aid in the study and advancement of Samskrit language, both for personal enrichment and professional application.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>3.5 Subscription and Free Access Policy</h5>
                                <p>While the majority of our users will access the platform through a subscription model, we offer free access to academic institutions, professors, and students engaged in Samskrit language studies. This policy is part of our commitment to promoting the Samskrit language and supporting educational endeavors in this field.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>3.6 Community Engagement</h5>
                                <p>All users, regardless of their subscription status, are encouraged to participate in the Samskrit Commons community. This feature of the platform allows for interaction, discussion, and knowledge exchange among a diverse group of learners and experts, fostering a vibrant learning community.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>3.7 Responsibilities and Expectations</h5>
                                <p>Users of aanantya.co are expected to adhere to a code of conduct that promotes respect, inclusivity, and a positive learning environment. The platform reserves the right to restrict or terminate access for users who violate these principles or engage in any form of misuse.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h3>Content Ownership</h3>
                                <h5>4.1 Source and Creation of Content</h5>
                                <p>Aanantya.co offers a diverse range of educational content, primarily focused on Samskrit language and culture. This content is created through a combination of methods: some are developed in-house by our team of educators and Samskrit experts, some are sourced from public domain materials, and others are generated using AI technologies like OpenAI's language models. Additionally, we feature content from Indian thought leaders, which is often translated into Samskrit for our platform.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>4.2 Copyright and Ownership</h5>
                                <p>The educational content on aanantya.co, unless specifically stated otherwise, is the property of Stunning Vistas Inc., the entity that operates aanantya.co. This includes all textual, visual, and interactive materials available on the platform. Content sourced from the public domain or third parties is used in compliance with applicable copyright laws and, where required, with the appropriate permissions.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>4.3 User-Generated Content</h5>
                                <p>Users of aanantya.co may contribute content to certain parts of the platform, such as forums, discussions, or community contributions. By submitting such content, users grant aanantya.co a non-exclusive, worldwide, royalty-free license to use, display, modify, distribute, and reproduce these contributions in connection with the operation of the platform and its promotion.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>4.4 Intellectual Property Respect</h5>
                                <p>Aanantya.co respects the intellectual property rights of others and expects its users to do the same. Any use of third-party copyrighted materials on our platform is done under the premise of fair use, license, or with express permission from the copyright owners.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>4.5 Prohibited Use of Content</h5>
                                <p>The content available on aanantya.co, including materials generated by AI, sourced from public domains, or created by third parties, is provided for personal, non-commercial use only. Users are prohibited from copying, redistributing, or using the content for commercial purposes without express written permission from aanantya.co or the respective copyright owners.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>4.6 Reporting Infringements</h5>
                                <p>If users believe that any content on aanantya.co infringes upon their own or any third party's intellectual property rights, they are encouraged to notify us immediately. Aanantya.co is committed to responding promptly to such notices and taking appropriate action in accordance with applicable intellectual property laws.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>4.7 Content Accuracy and Reliability</h5>
                                <p>While aanantya.co strives to ensure the accuracy and reliability of the content, we do not guarantee that all information provided is always correct or up-to-date. The nature of language learning, especially in a language as rich and complex as Samskrit, means that there may be multiple valid interpretations or translations.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>4.8 Changes and Updates to Content</h5>
                                <p>Aanantya.co reserves the right to modify, update, or delete any content on the platform at any time, without prior notice, to improve the quality and relevance of our educational materials.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h3>Subscription Model</h3>
                                <h5>5.1 Overview of Subscription Options</h5>
                                <p>Aanantya.co operates on a subscription-based model, offering various tiers of service to accommodate the needs and preferences of different users. Our subscription plans include 'Hiranya Antariksha', 'Jatarupa Nabhas', and 'Suvarna Vyoma', each offering a unique set of features and access levels to our resources and services.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>5.2 Subscription Fees and Payments</h5>
                                <p>All users who wish to access the premium content and features of aanantya.co are required to subscribe to one of our available plans. The subscription fees are clearly listed on our platform and may be subject to change. We accept various forms of payment, and all transactions are processed securely.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>5.3 Free Access for Eligible Users</h5>
                                <p>In line with our commitment to educational advancement, aanantya.co offers free access to academic institutions, professors, and students pursuing Samskrit studies. Eligibility for free access is determined by aanantya.co and may require submission of relevant documentation for verification.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>5.4 Renewals and Cancellations</h5>
                                <p>Subscriptions are automatically renewed at the end of each subscription period unless cancelled by the user. Users can manage their subscription settings, including cancellation, through their account dashboard. Cancellation requests must be submitted at least 48 hours before the renewal date to avoid charges for the next period.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>5.5 No Refund Policy</h5>
                                <p>All subscription fees are non-refundable. Once a user subscribes to a plan, they agree to pay the subscription fee for the entire period. Refunds are not provided for partial use or non-use of the services.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>5.6 Modifications to Subscription Plans</h5>
                                <p>Aanantya.co reserves the right to modify, add, or discontinue any subscription plan or feature at any time. Any changes to the subscription plans will be communicated to existing subscribers and will not affect the current subscription period.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>5.7 Account Responsibility</h5>
                                <p>Subscribers are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account. Subscribers must promptly notify aanantya.co of any unauthorized use of their account.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>5.8 Usage Limits</h5>
                                <p>Each subscription plan comes with specific usage limits and conditions, detailed on our platform. Exceeding these limits may result in additional charges or temporary suspension of the account until the start of the next subscription period.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>5.9 Marketing and Promotions</h5>
                                <p>Aanantya.co may, from time to time, offer promotional discounts or special terms for subscriptions. These promotions are subject to additional terms and conditions and may be offered for a limited time.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>5.10 Compliance with Terms</h5>
                                <p>By subscribing to any plan on aanantya.co, users agree to comply with these Terms and Conditions, as well as any additional terms specific to their chosen subscription plan.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h3>Privacy Policy</h3>
                                <h5>6.1 Collection of Personal Data</h5>
                                <p>Aanantya.co collects personal data from users to provide and improve our services. This data may include, but is not limited to, user names, email addresses, usage data, preferences, and feedback. We are committed to ensuring the privacy and security of all personal information collected.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>6.2 Use of Personal Data</h5>
                                <p>The personal data collected is primarily used to personalize the learning experience, manage user accounts, provide customer support, and improve our services. Additionally, we use this data to inform users about updates, new features, and promotional offers that may be of interest.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>6.3 Data Sharing and Disclosure</h5>
                                <p>Aanantya.co does not sell or rent user personal data to third parties. We may share this data with service providers who perform services on our behalf, such as hosting, data analysis, and marketing assistance. These service providers are bound by confidentiality agreements and are not permitted to use the data for any other purpose.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>6.4 Data Security</h5>
                                <p>We implement a variety of security measures to maintain the safety of your personal information. Despite these measures, please note that no method of transmission over the Internet or method of electronic storage is 100% secure.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>6.5 User Rights and Access</h5>
                                <p>Users have the right to access, update, or delete their personal information stored on aanantya.co. Requests for access or deletion of personal data can be made through the user's account settings or by contacting our customer service.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>6.6 Data Retention</h5>
                                <p>Personal data will be retained only for as long as necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>6.7 Cookies and Tracking Technologies</h5>
                                <p>Aanantya.co uses cookies and similar tracking technologies to track activity on our platform and store certain information. These technologies are used to enhance the user experience, understand usage patterns, and tailor our services to user preferences.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>6.8 Changes to Privacy Policy</h5>
                                <p>We reserve the right to update this Privacy Policy at any time. We will notify users of any changes by posting the new Privacy Policy on this page and updating the "effective date" at the top of this Privacy Policy.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>6.9 Consent</h5>
                                <p>By using aanantya.co, users consent to the collection and use of information in accordance with this Privacy Policy. If we decide to change our Privacy Policy, we will post those changes on this page so that users are always aware of what information we collect, how we use it, and under what circumstances we disclose it.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>6.10 Contact Information</h5>
                                <p>For any questions or concerns regarding this Privacy Policy or the handling of personal data, users can contact us at [specified contact information].</p>
                                <p>This expanded section outlines the privacy practices of aanantya.co, detailing how user data is collected, used, and protected. It's crucial to clearly communicate these practices to users to build trust and ensure compliance with data protection laws. As with other sections of the Terms and Conditions, it's advisable to have a legal professional review this section to ensure compliance with local and international privacy laws and regulations.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h3>Interactivity and Community Engagement</h3>
                                <h5>7.1 Interactive Features</h5>
                                <p>Aanantya.co offers various interactive features including, but not limited to, forums, chat rooms, live sessions, and community boards (collectively referred to as “Interactive Services”). These services are designed to enhance the learning experience and facilitate community engagement among users.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>7.2 Community Participation</h5>
                                <p>All users are encouraged to actively participate in the Samskrit Commons, our vibrant online community. This platform allows users to interact with fellow learners, share insights, and engage in discussions related to Samskrit language and culture.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>7.3 Conduct and Behavior</h5>
                                <p>Users are expected to maintain a respectful and constructive environment within all interactive areas of aanantya.co. Harassment, bullying, discrimination, or any form of inappropriate behavior is strictly prohibited. Users must adhere to the principles of respect and courtesy at all times.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>7.4 Content Standards</h5>
                                <p>Any content shared or posted by users in the interactive areas must comply with our content standards. This includes ensuring that the content is accurate (where it states facts), genuinely held (where it states opinions), and compliant with applicable laws. The content should not infringe any intellectual property right, nor should it be defamatory, obscene, offensive, or capable of inciting violence or hatred.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>7.5 Monitoring and Enforcement</h5>
                                <p>Aanantya.co reserves the right, but not the obligation, to monitor and review activity and content in the interactive areas. We may remove or modify any content that we deem to be in violation of these Terms and Conditions or our community standards.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>7.6 User-Generated Content</h5>
                                <p>By submitting content to any part of aanantya.co, including forums and chat rooms, users grant aanantya.co a non-exclusive, royalty-free, perpetual, and irrevocable right to use, reproduce, modify, adapt, publish, translate, distribute, and display such content throughout the world in any media.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>7.7 Responsibility for User-Generated Content</h5>
                                <p>Users are solely responsible for the content they contribute to the interactive areas. Aanantya.co is not responsible or liable for the content or accuracy of any materials posted by users or any third party.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>7.8 Reporting Inappropriate Content</h5>
                                <p>Users can report inappropriate or offensive content encountered in the interactive areas to aanantya.co for review. We are committed to taking appropriate action to maintain a safe and respectful community.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>7.9 Access and Availability</h5>
                                <p>Aanantya.co strives to keep the interactive services accessible and functional at all times but does not guarantee uninterrupted access. Access to these services may be suspended temporarily without notice in the case of system failure, maintenance, or repair.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>7.10 Changes to Interactive Services</h5>
                                <p>Aanantya.co reserves the right to change, suspend, or discontinue any aspect of the interactive services at any time, including the availability of any feature, without notice or liability.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h3>Technology Use</h3>
                                <h5>8.1 Overview of Technology Integration</h5>
                                <p>Aanantya.co utilizes a range of advanced technologies to deliver and enhance its educational services. This includes but is not limited to AI-driven tutoring, interactive tools, video sessions, augmented reality (AR), and virtual reality (VR) in future implementations.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>8.2 AI-Driven Tools</h5>
                                <p>Our platform employs AI technologies, including language models developed by OpenAI, to provide personalized and interactive learning experiences. These tools assist in language tutoring, content generation, and user interaction.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>8.3 Augmented and Virtual Reality Features</h5>
                                <p>Aanantya.co plans to incorporate AR and VR technologies to offer immersive learning experiences. These features will allow users to engage with Samskrit language and culture in a dynamic and interactive manner.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>8.4 Continuous Technological Advancements</h5>
                                <p>We are committed to continuously updating and integrating new technological advancements into our platform to improve the quality of our services and enhance the user experience.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>8.5 User Requirements</h5>
                                <p>To access and utilize the full range of features offered by aanantya.co, users may be required to have compatible devices and software. It is the user's responsibility to ensure that their technology meets the necessary specifications.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>8.6 Reliability and Availability</h5>
                                <p>While aanantya.co endeavors to ensure the reliability and availability of its technological features, we do not guarantee uninterrupted or error-free operation. Technical issues may arise due to various factors, some of which may be beyond our control.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>8.7 User Feedback and Improvement</h5>
                                <p>User feedback is vital in helping us improve our technological tools and features. We encourage users to provide constructive feedback, which we will consider in our ongoing development efforts.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>8.8 Data Usage and Storage</h5>
                                <p>The use of AI and other technologies on aanantya.co may involve the processing and storage of user data. We adhere to strict data protection policies and ensure that user data is handled securely and responsibly, as detailed in our Privacy Policy.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>8.9 Technology Licensing and Intellectual Property</h5>
                                <p>The technologies used on aanantya.co are licensed from or owned by aanantya.co or its licensors. Users are granted a limited, non-exclusive right to access and use these technologies as part of the services offered on the platform.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>8.10 Changes and Updates to Technology</h5>
                                <p>Aanantya.co reserves the right to modify, update, or discontinue any technological feature or tool on the platform. We will make reasonable efforts to notify users of significant changes that could affect their use of the services.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h3>Limitations and Liabilities</h3>
                                <h5>9.1 Limitation of Liability</h5>
                                <p>Aanantya.co, including its directors, employees, partners, agents, suppliers, or affiliates, shall not be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the services; (ii) any conduct or content of any third party on the services; (iii) any content obtained from the services; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>9.2 Accuracy of AI-Generated Content</h5>
                                <p>While aanantya.co utilizes AI-driven tools to enhance the learning experience, we do not guarantee the absolute accuracy of the AI-generated content. Users are advised that AI tools, including language models, can produce unexpected or incorrect results and should use discretion and verify information independently.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>9.3 Indemnification</h5>
                                <p>You agree to defend, indemnify, and hold harmless aanantya.co and its licensee and licensors, and their employees, contractors, agents, officers, and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the service, by you or any person using your account and password, or b) a breach of these Terms.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>9.4 Disclaimer of Warranties</h5>
                                <p>Your use of the services is at your sole risk. The services are provided on an "AS IS" and "AS AVAILABLE" basis. The services are provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>9.5 Limitations on Claim</h5>
                                <p>Any claim or cause of action you may have arising out of or relating to these Terms or the services must be commenced within one (1) year after the cause of action accrues, otherwise, such claim or cause of action is permanently barred.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>9.6 Changes in Services</h5>
                                <p>Aanantya.co reserves the right to modify or discontinue, temporarily or permanently, the service (or any part thereof) with or without notice. You agree that aanantya.co shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the service.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>9.7 Reliance on Information</h5>
                                <p>The information presented on or through the services is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>9.8 Exclusions</h5>
                                <p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h3>Intellectual Property</h3>
                                <h5>10.1 Ownership of Intellectual Property</h5>
                                <p>All content, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) on aanantya.co are owned by Stunning Vistas Inc., its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>10.2 Use of Aanantya.co Content</h5>
                                <p>The intellectual property rights in all content provided on aanantya.co are the property of Stunning Vistas Inc. or its licensors. This content is provided to users for their personal, non-commercial use only. Users are not permitted to use, copy, modify, distribute, transmit, display, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from aanantya.co without express written consent.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>10.3 User Contributions</h5>
                                <p>Users may submit content, including text, images, and other materials, to aanantya.co. By submitting such content, users grant aanantya.co a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>10.4 Prohibited Uses of Intellectual Property</h5>
                                <p>It is prohibited for users to infringe upon or violate the intellectual property rights of aanantya.co or others. This includes any unauthorized use, reproduction, or distribution of any content or material available on aanantya.co.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>10.5 Reporting Intellectual Property Infringement</h5>
                                <p>If you believe that your intellectual property rights have been violated in any way by content or material on aanantya.co, please contact us immediately to report the infringement. Aanantya.co is committed to respecting the intellectual property rights of others and will take appropriate action to address any infringement claims.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>10.6 Acknowledgment and Attribution</h5>
                                <p>In cases where users wish to use any Samskrit translation or answer from aanantya.co for official or professional purposes, they must seek permission from aanantya.co and provide appropriate acknowledgment as specified by aanantya.co.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>10.7 Restrictions on Content Use</h5>
                                <p>Content generated by aanantya.co, including questions and answers, is owned by aanantya.co. Downloading, reproducing, or using this content to feed a user's own or affiliated language teaching sites is strictly prohibited and may constitute a breach of intellectual property laws.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>10.8 Subscription Sharing and Unauthorized Distribution</h5>
                                <p>Sharing of subscriptions is strictly prohibited, except within a family. Unauthorized distribution or sharing of content from aanantya.co, including through photography, printouts, or digital means, is strictly prohibited and may result in legal action.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>10.9 User Agreement to Terms</h5>
                                <p>By accessing and using aanantya.co, users agree to abide by these intellectual property terms and acknowledge that violation of these terms can result in legal consequences and termination of access to the services.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h3>Dispute Resolution</h3>
                                <h5>11.1 Governing Law</h5>
                                <p>These Terms and Conditions and any separate agreements whereby we provide you services shall be governed by and construed in accordance with the laws of the State of Texas, United States, without regard to its conflict of law principles.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>11.2 Dispute Resolution Mechanism</h5>
                                <p>In the event of any dispute, claim, or controversy arising out of or in connection with these Terms and Conditions or the breach, termination, enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, such dispute, claim, or controversy will be, to the fullest extent permitted by law, determined by arbitration in Houston, Texas, before one arbitrator.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>11.3 Arbitration Process</h5>
                                <p>The arbitration shall be administered by the American Arbitration Association (AAA) pursuant to its Commercial Arbitration Rules and, where appropriate, the AAA’s Supplementary Procedures for Consumer Related Disputes. The arbitrator’s decision shall be binding and may be entered as a judgment in any court of competent jurisdiction.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>11.4 No Class Actions</h5>
                                <p>You agree that any arbitration or proceeding shall be limited to the dispute between us and you individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other; (b) there is no right or authority for any dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (c) there is no right or authority for any dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>11.5 Exceptions to Arbitration</h5>
                                <p>Notwithstanding the above, you agree that the following matters are not subject to the above terms of arbitration: (i) any claim seeking to enforce or protect, or concerning the validity of, any of your or aanantya.co's intellectual property rights; (ii) any claim related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (iii) any claim for injunctive relief.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>11.6 Notice of Dispute</h5>
                                <p>A party who intends to seek arbitration must first send a written notice of the dispute to the other, by certified mail or Federal Express (signature required), or in the event that we do not have a physical address on file for you, by electronic mail ("Notice of Dispute"). The Notice of Dispute must (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>11.7 Time Limit for Filing Claims</h5>
                                <p>Any claim you may have arising out of or related to your relationship with aanantya.co must be filed within one year after such claim arose; otherwise, your claim is permanently barred.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>11.8 Jurisdiction for Legal Disputes Not Subject to Arbitration</h5>
                                <p>Any legal suit, action, or proceeding arising out of, or related to, these Terms and Conditions or the services that is not subject to arbitration shall be instituted exclusively in the federal courts of the United States or the courts of the State of Texas in each case located in Houston and Harris County, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms and Conditions in your country of residence or any other relevant country.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h3>Changes to Terms</h3>
                                <h5>12.1 Right to Modify Terms</h5>
                                <p>Aanantya.co reserves the right, at its sole discretion, to modify or replace these Terms and Conditions at any time. Changes may be necessary to accommodate updates in the law, new regulatory requirements, enhancements to the services, or other reasons.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>12.2 Notification of Changes</h5>
                                <p>In the event of any significant changes to the Terms and Conditions, aanantya.co will provide notice to users through the platform or via email. The notice will include a summary of the key changes and the effective date of the new Terms and Conditions.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>12.3 User's Responsibility to Stay Informed</h5>
                                <p>Users are encouraged to review the Terms and Conditions periodically to stay informed of any updates. Continued use of the services after the effective date of any changes will constitute acceptance of, and agreement to be bound by, the new Terms and Conditions.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>12.4 No Retrospective Changes</h5>
                                <p>Any changes to the Terms and Conditions will not apply retrospectively and will only apply to disputes or issues arising after the effective date of the changed Terms and Conditions.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>12.5 Archival of Previous Versions</h5>
                                <p>Aanantya.co will maintain an archive of previous versions of the Terms and Conditions for users' reference. Users can access these historical versions to understand how the Terms and Conditions have evolved over time.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>12.6 User Feedback on Changes</h5>
                                <p>Users are welcome to provide feedback on the revised Terms and Conditions. While not all feedback may result in changes, we value user input and strive to incorporate suggestions that enhance the clarity and fairness of our Terms.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>12.7 Binding Nature of Revised Terms</h5>
                                <p>The revised Terms and Conditions will be binding upon users from the effective date. If you disagree with the revised Terms, your sole recourse is to discontinue the use of aanantya.co services.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>12.8 Documentation of Acceptance</h5>
                                <p>For certain changes, aanantya.co may require users to provide explicit consent to the updated Terms and Conditions. This may involve clicking "agree" or taking similar actions to indicate acceptance.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>12.9 Changes in Response to Legal and Regulatory Developments</h5>
                                <p>In some cases, changes to the Terms and Conditions may be required in response to new legal and regulatory developments. Aanantya.co is committed to compliance with all applicable laws and regulations and will update the Terms accordingly.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h3>Governing Law</h3>
                                <h5>13.1 Applicability of Texas Law</h5>
                                <p>These Terms and Conditions, and any separate agreements whereby we provide you services, shall be governed by and construed in accordance with the laws of the State of Texas, United States. This applies regardless of your location, nationality, or the jurisdiction from which you access our services.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>13.2 Jurisdiction</h5>
                                <p>By using aanantya.co, you agree that any disputes or claims arising out of or in connection with these Terms and Conditions, or your use of the services, shall be subject to the exclusive jurisdiction of the state and federal courts located in Texas. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>13.3 Compliance with Local Laws</h5>
                                <p>Users are responsible for complying with local laws in their jurisdiction, to the extent those laws are applicable. Aanantya.co makes no representation that the services are appropriate or available for use in locations outside of Texas or the United States.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>13.4 Conflict of Laws</h5>
                                <p>In the event of any conflict between the provisions of these Terms and Conditions and any applicable local laws, the provisions of these Terms and Conditions will prevail, subject to the limitations of mandatory local laws.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>13.5 Legal Effectiveness</h5>
                                <p>If any provision of these Terms and Conditions is found by a court of competent jurisdiction to be invalid, illegal, or unenforceable, it will not affect the validity of the remaining provisions, which will remain in full force and effect.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>13.6 No Waiver</h5>
                                <p>No waiver by aanantya.co of any term or condition set forth in these Terms and Conditions shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of aanantya.co to assert a right or provision under these Terms and Conditions shall not constitute a waiver of such right or provision.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>13.7 Statute of Limitations</h5>
                                <p>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of, related to or connected with the use of the services or these Terms and Conditions must be filed within one (1) year after such claim or cause of action arose or be forever barred.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>13.8 Legal Proceedings</h5>
                                <p>In the event of any legal proceedings arising from these Terms and Conditions or your use of the services, aanantya.co is entitled to recover reasonable attorneys’ fees and costs, in addition to any other relief granted.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>13.9 Interpretation of Terms</h5>
                                <p>The section headings in these Terms and Conditions are for convenience only and have no legal or contractual effect. Any ambiguities in the interpretation of these Terms and Conditions shall not be construed against the drafting party.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h3>Prohibited Activities</h3>
                                <h5>14.1 Overview</h5>
                                <p>Aanantya.co rigorously upholds a secure, respectful, and legally compliant online environment. Strict adherence to legal and ethical standards is demanded; failure to comply will invoke decisive actions, including but not limited to, suspension or termination of user accounts, and may lead to legal prosecution.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>14.2 Prohibition of Illegal Conduct</h5>
                                <p>Engaging in any activity deemed illegal under federal, state, or international law is expressly forbidden. This encompasses a broad spectrum of illicit acts including fraud, theft, embezzlement, money laundering, and participation in criminal networks or enterprises.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>14.3 Intellectual Property Rights</h5>
                                <p>Respect for intellectual property rights is paramount. Users are prohibited from engaging in any form of intellectual property infringement. This includes unauthorized reproduction, distribution, alteration, or exploitation of this website materials and of original works of individuals of AI posting content on the website or of copyrighted or patented materials.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>14.4 Zero Tolerance for Harassment</h5>
                                <p>Harassment in any manifestation, including cyberbullying, stalking, and unauthorized dissemination of personal information (doxxing), is strictly prohibited. Aanantya.co enforces a zero-tolerance policy against any form of harassment or abuse towards users, staff, or any third parties.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>14.5 Discrimination and Hate Speech</h5>
                                <p>Expressions of hate speech, discriminatory commentary, or content promoting intolerance or violence against any individual or group based on race, ethnicity, religion, gender identity, sexual orientation, disability, or national origin are unequivocally banned.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h5>14.6 Obscenity and Exploitation</h5>
                                <p>The posting or distribution of exploitative, obscene, or pornographic material is strictly forbidden. This explicitly includes materials related to child sexual abuse, non-consensual pornography, or any form of content that exploits or harms minors.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>14.7 Misinformation and Impersonation</h5>
                                <p>Disseminating false information, engaging in deceptive practices, or impersonating others with the intent to mislead, defraud, or harm others is a serious offense and strictly prohibited.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>14.8 Unauthorized Commercial Use</h5>
                                <p>Users are prohibited from engaging in unauthorized commercial activities, advertising, or solicitation on aanantya.co. Prohibited activities include spamming, involvement in pyramid schemes, and phishing activities.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>14.9 Security Violations</h5>
                                <p>Attempts to compromise the security of the website, such as hacking, disseminating viruses, or introducing other malicious software, and unauthorized access to restricted areas of the website, are strictly prohibited.</p>
                            </div>    
                            <div class="about-us-cntn">
                                <h3></h3>
                                <h5>14.10 Privacy Violations</h5>
                                <p>Infringing upon the privacy rights of others, including unauthorized sharing of private communications or personal data, is prohibited.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h3></h3>
                                <h5>14.11 Duty to Report and Compliance Enforcement</h5>
                                <p>Users are obligated to report instances of prohibited activities. Aanantya.co will take necessary actions to investigate and rectify reported violations. Enforcement measures may include collaboration with law enforcement authorities.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>14.12 User Compliance Responsibility</h5>
                                <p>Users bear the responsibility to be fully aware of and comply with these prohibitions. Ignorance of these rules is not an acceptable excuse for engaging in prohibited activities.</p>
                            </div>
                            <div class="about-us-cntn">
                                <h5>14.13 Legal Consequences of Violations</h5>
                                <p>Violations of these prohibitions can lead to immediate suspension or termination of access to aanantya.co’s services and may result in legal proceedings, contingent on the severity and nature of the violation.</p>
                            </div>    -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <beforeLoginChatbot></beforeLoginChatbot>
    </template>
    
    <script>
    
    import { API } from "@/api/api";
    import beforeLoginChatbot from '../views/beforeLoginChatbot.vue';
    
    export default {
        components: {
            // eslint-disable-next-line vue/no-unused-components
            beforeLoginChatbot
        },
      data() {
        return {
            contentData: null
        };
      },

      created() {
    this.getContent();
  },
      
      methods: {
        getContent(){
            API.get("generic/terms")
      .then(response => {
                this.contentData = response.data.data;
                console.log(this.contentData, "After got contentData");
            }).catch(error => {
                  console.error("fail", error);
                  });
        }
      },
    };
    </script>
    